import * as React from "react"
import { Link } from "gatsby"
import { Parallax } from "@react-spring/parallax"
import { Themed } from "theme-ui"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import { UpDown, UpDownWide, waveAnimation} from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import Svg from "@lekoarts/gatsby-theme-cara/src/components/svg"
import Seo from "@lekoarts/gatsby-theme-cara/src/components/seo"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Footer from "../@lekoarts/gatsby-theme-cara/components/footer"

const NotFound = () => (
    <Layout>
        <Seo title="oganesson - Nicht freigeschaltet" />
        <Parallax pages={2}>
            <div>
                <Divider speed={0.2} offset={0} factor={1}>
                    <UpDown>
                        <Svg icon="triangle" hiddenMobile width={48} stroke color="icon_orange" left="10%" top="20%" />
                        <Svg icon="hexa" width={48} stroke color="icon_red" left="60%" top="70%" />
                        <Svg icon="box" width={6} color="icon_darker" left="60%" top="15%" />
                    </UpDown>
                    <UpDownWide>
                        <Svg icon="arrowUp" hiddenMobile width={16} color="icon_blue" left="80%" top="10%" />
                        <Svg icon="triangle" width={12} stroke color="icon_brightest" left="90%" top="50%" />
                        <Svg icon="circle" width={16} color="icon_darker" left="70%" top="90%" />
                        <Svg icon="triangle" width={16} stroke color="icon_darkest" left="30%" top="65%" />
                        <Svg icon="cross" width={16} stroke color="icon_pink" left="28%" top="15%" />
                        <Svg icon="circle" width={6} color="icon_darkest" left="75%" top="10%" />
                        <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
                    </UpDownWide>
                    <Svg icon="circle" hiddenMobile width={24} color="icon_darker" left="5%" top="70%" />
                    <Svg icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
                    <Svg icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
                    <Svg icon="upDown" width={8} color="icon_darkest" left="95%" top="90%" />
                    <Svg icon="upDown" hiddenMobile width={24} color="icon_darker" left="40%" top="80%" />
                    <Svg icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />
                    <Svg icon="circle" width={64} color="icon_green" left="95%" top="5%" />
                    <Svg icon="box" hiddenMobile width={64} color="icon_purple" left="5%" top="90%" />
                    <Svg icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
                    <Svg icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
                    <Svg icon="hexa" width={16} stroke color="icon_darker" left="10%" top="50%" />
                    <Svg icon="hexa" width={8} stroke color="icon_darker" left="80%" top="70%" />
                </Divider>
                <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={0} factor={1}>
                    <Inner>
                        <Themed.h1>oganesson</Themed.h1>
                        <Themed.h3>Der Inhalt dieser Seite unterliegt der Geheimhaltung und bedarf einer Freischaltung durch den Projektträger.</Themed.h3>
                        <Themed.p>
                            Zurück zur <Link to="/">Startseite</Link>.
                        </Themed.p>
                    </Inner>
                </Content>
            </div>
            <Divider fill="divider" speed={0.2} offset={1} factor={1}>
                <div sx={{ position: `absolute`, bottom: 0, width: `full`, transform: `matrix(1, 0, 0, -1, 0, 0)` }}>
                    <div
                        sx={{
                            position: `relative`,
                            height: `full`,
                            svg: { width: `100%`, height: `40vh` },
                            path: { animation: waveAnimation(`20s`) },
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" id="contact-wave" viewBox="0 0 800 338.05" preserveAspectRatio="none">
                            <path>
                                <animate
                                    attributeName="d"
                                    values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                                    repeatCount="indefinite"
                                    dur="30s"
                                />
                            </path>
                        </svg>
                    </div>
                </div>
            </Divider>
            <Content speed={0.4} offset={1} factor={1}>
                <Footer />
            </Content>
            <Divider speed={0.1} offset={1} factor={1}>
                <UpDown>
                    <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="70%" top="20%" />
                    <Svg icon="triangle" width={8} stroke color="icon_darkest" left="25%" top="5%" />
                </UpDown>
                <UpDownWide>
                    <Svg icon="triangle" width={12} stroke color="icon_brightest" left="95%" top="50%" />
                    <Svg icon="circle" width={6} color="icon_brightest" left="85%" top="15%" />
                    <Svg icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
                </UpDownWide>
                <Svg icon="circle" width={6} color="icon_brightest" left="4%" top="20%" />
                <Svg icon="circle" width={12} color="icon_darkest" left="70%" top="60%" />
                <Svg icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
                <Svg icon="hexa" width={8} stroke color="icon_darkest" left="80%" top="70%" />
            </Divider>
        </Parallax>
    </Layout>
)

export default NotFound
